import { render, staticRenderFns } from "./PaintingCard.vue?vue&type=template&id=0b53071f&scoped=true&"
import script from "./PaintingCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./PaintingCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./PaintingCard.vue?vue&type=style&index=0&id=0b53071f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b53071f",
  null
  
)

export default component.exports