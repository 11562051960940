var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon",class:{
    '--path-stroke': !_vm.noPathStroke,
    '--path-fill': !_vm.noPathFill,
    '--g-fill': !_vm.noGFill
  },style:({
    width: `${_setup.widthComp === 'auto' ? 'auto' : _setup.widthComp + 'px'}`,
    height: `${_setup.heightComp === 'auto' ? 'auto' : _setup.heightComp + 'px'}`,
    minWidth: `${_setup.heightComp === 'auto' ? 'auto' : _setup.widthComp + 'px'}`,
    minHeight: `${_setup.heightComp === 'auto' ? 'auto' : _setup.heightComp + 'px'}`,
    '--color': _vm.color})},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }